import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import './App.css';
import Review from './Review';
//import mascoteBibi from './mascote-bibi.png';
import telefone from '../src/telefone.jpeg';
import fone from './fone.jpg';
//import holho from './favicon.png'
/*
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
*/
/*
import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import telefone from '../src/telefone.jpeg';

const { nome, idade } = this.state;
const mensagem = `Olá, meu nome é ${nome} e tenho ${idade} anos. Gostaria de entrar em contato.`;

const linkWhatsApp = `https://api.whatsapp.com/send?phone=5586999060047&text=${encodeURIComponent(mensagem)}`;


*/

/*
class SimpleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      idade: '',
    };
  }

  handleEnd = ({ steps, values }) => {
    const { nome, idade } = values;
    const mensagem = `Olá, meu nome é ${nome} e tenho ${idade} anos. Gostaria de entrar em contato.`;
    const linkWhatsApp = `https://api.whatsapp.com/send?phone=5586999060047&text=${encodeURIComponent(mensagem)}`;
    window.open(linkWhatsApp, '_blank');
  };
*/
class SimpleForm extends Component {
  render() {
    return (
      <ChatBot
        steps={[
          {
            id: '1',
            message: 'Olá! Meu nome é Bibi, sou Assistente Virtual do HOT.',
            trigger: '2',
          },
          {
            id: '2',
            message: 'Bem vindo! Qual é o seu nome?',
            trigger: 'name',
          },
          {
            id: 'name',
            user: true,
            trigger: '3',
          },
          
          /*
            {
              id: '3',
              message: 'Oi {previousValue}! Qual o seu gênero?',
              trigger: 'gender',
            },
            {
              id: 'gender',
              options: [
                { value: 'male', label: 'Masculino', trigger: '5' },
                { value: 'female', label: 'Feminino', trigger: '5' },
              ],
            },
            

          {
            id: '3',
            message: 'Oi {previousValue}! Quantos anos você tem?',
            trigger: 'age',
          },
          {
            id: 'age',
            user: true,
            trigger: '6',
            validator: (value) => {
              if (isNaN(value)) {
                return 'Apenas números'
              } else if (value < 0) {
                return 'O valor precisa ser positivo'
              } else if (value > 110) {
                return `${value}? Tente novamente.`
              }
              return true
            },
          },
          {
            id: '6',
            message: 'Qual sua data de nascimento?',
            trigger: 'date',
          },
          {
            id: 'date',
            user: true,
            trigger: '7',
          },
          {
            id: '7',
            message: 'Otimo! aqui está um resumo:',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
          },
          {
            id: 'update',
            message: 'Você quer atualizar algo?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Sim', trigger: 'update-yes' },
              { value: 'no', label: 'Não', trigger: 'end' },
            ],
          },
          {
            id: 'update-yes',
            message: 'Aqui está o que pode ser atualizado',
            trigger: 'update-fields',
          },
          {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Nome', trigger: 'update-name' },
              {
                /* value: 'gender', label: 'Genero', trigger: 'update-gender' *//*
              },
              { value: 'age', label: 'Idade', trigger: 'update-age' },
              { value: 'date', label: 'Nascimento', trigger: 'update-date' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: '7',
          },
          {
            id: 'update-gender',
            update: 'gender',
            trigger: '7',
          },
          {
            id: 'update-age',
            update: 'age',
            trigger: '7',
          },
          {
            id: 'update-date',
            update: 'date',
            trigger: '7',
          },
          {
            id: 'end', //end-message
            message:
              'Obrigado! Seus dados estão no nosso banco de dados e nossa equipe irá entrar em contato!',
            //end: true,
            trigger: 'end',
          },
          */
          {
            id: '3',
            message:
              'Oi {previousValue}! Você também pode conversar diretamente com a nossa equipe clicando no botão abaixo.',
            trigger: 'mensagem',
          },
          {
            id: 'mensagem',
            component: (
              <div
                class="sc-bZQynM hQsUiY rsc-ts-bubble"
                style={{
                  backgroundColor: '',
                  border: 'none',
                  height: '50%',
                  borderRadius: '50px',
                }}
              >
                {/*<input style={{'display':'flex'}} type="text" id="mensagemInput" placeholder="Digite sua mensagem" />*/}
                <button
                  style={{
                    background: '#2fb85d',
                    borderRadius: '22px',
                    'box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
                    color: '#fff',
                    display: 'inline-block',
                    fontSize: '14px',
                    padding: '12px',
                    border: 'none',
                  }}
                >
                  <a
                    style={{
                      fontFamily: 'ui-system',
                      color: '#fff',
                      alignItems: 'center',
                      textDecoration: 'none',
                    }}
                    href="https://api.whatsapp.com/send?phone=5586999060047&text=Olá,%20gostaria%20de%20entrar%20em%20contato.%20"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enviar Mensagem
                  </a>
                </button>
              </div>
            ),
            trigger: 'update-dados',
          },
          {
            id: 'update-dados',
            options: [
              {
                value: 'location',
                label: 'Localização',
                trigger: 'location-map',
              },
              {
                value:'street',
                label:'Visita Online',
                trigger:'streetview',
              },
              {
                value: 'telefone',
                label: 'Telefone',
                trigger: 'telefone-fixo',
              },
              {
                value:'finalizar',
                label:'Encerrar',
                trigger:'restart',
              },
            ],
          },
          
          {
            id: 'location-map',
            message: 'Nossa localização',
            trigger: 'location',
          },
          {
            id: 'location',
            component: (
              <div style={{ borderRadius: '10px', border:'none'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15896.339595311742!2d-42.8063061!3d-5.089973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ed243316eeb5207!2sHospital+do+Olho!5e0!3m2!1spt-BR!2sbr!4v1532353072579" width="100%" height="300" frameborder="0" style={{border:"0", borderRadius:'20px'}} allowfullscreen>
                </iframe>
                <p width="100%" height="10px" style={{backgroundColor:'#fdfdfd', borderRadius:'5px', alignContent:'center', textAlign:'center'}}>Rua Magalhães Filho, 161</p>
              </div>
            ),
            trigger: 'update-dados',
          },
          {
            id: 'update-dados1',
            options: [
              {
                value: 'street',
                label:'Visita Online',
                trigger:'streetview',
              },
              {
                value: 'telefone',
                label: 'Telefone',
                trigger: 'telefone-fixo',
              },
              {
                value:'finalizar',
                label:'Encerrar',
                trigger:'fim',
              },
            ],
          },
          {
            id: 'streetview',
            message:'Conheça o Hospital do Olho',
            trigger:'street',
          },
          {
            id: 'street',
            component: (
              <div style={{ borderRadius: '10px'}}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!4v1534269552206!6m8!1m7!1sCAoSLEFGMVFpcE9lU2lsczRnNXotS3I3RnBZc2VUV0ZOeGdWNGFFWXJSNnZmeU5y!2m2!1d-5.089913939210852!2d-42.80633948740149!3f213.7330357153387!4f-4.580521857715439!5f0.7820865974627469"
                  width="100%" height="450" frameborder="0" style={{border:'0'}} allowfullscreen>  
                </iframe>
              </div>
            ),
            trigger:'update-dados',
          },
          {
            id:'update-dados2',
            options: [
              {
                value: 'telefone',
                label: 'Telefone',
                trigger: 'telefone-fixo',
              },
              {
                value:'finalizar',
                label:'Encerrar',
                trigger:'restart',
              },
            ],
          },
          {
            id: 'telefone-fixo',
            message:'3194-1000',
            trigger:'update-dados',
          },
          {
            id:'update-dados3',
            options: [
              {
                id:'restart',
                value:'Reiniciar chat',
                trigger:'restart',
              },
            ],
          },
          /*
          {
            id: 'update-dd',
            message:
              'Você permite o armazenamento dos seus dados para que possamos entrar em contato?',
            trigger: 'update-confirm',
          },
          {
            id: 'update-confirm',
            options: [
              { value: 'yes', label: 'Sim', trigger: 'end-message' },
              { value: 'no', label: 'Não', trigger: 'encerrar' },
            ],
          },
          {
            id: 'end-message',
            message:
              'Obrigado! Seus dados estão no nosso banco de dados e nossa equipe irá entrar em contato!',
            trigger:'restart',
          },
          */
          {
            id: 'update-dd',
            message: 'Obrigado por entrar em contato conosco, até breve!',
            trigger:'restart',
          },
          {
            id: 'restart',
            message: 'Você deseja reiniciar a conversa?',
            trigger: 'restart-options',
          },
          {
            id: 'restart-options',
            options: [
              { value: 'yes', label: 'Sim', trigger: '1' }, // O ID '1' é o primeiro passo do seu chatbot
              { value: 'no', label: 'Não', trigger: 'fim' }, // O ID 'fim' deve ser um novo passo que finalize a conversa
            ],
          },          
          {
            id: 'fim',
            message:
              'Obrigado! tchau!!!',
            end: true,
          },
        ]}
      />
    )
  }
}

export default SimpleForm
