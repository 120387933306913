import React, { useState } from "react";
import SimpleForm from "./SimpleForm";
import "./App.css";
//import "./style.css"
import "./fone.jpg";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
//import {ThemeProvider} from "styled-components";

const App = (props) => {
  /*
  const theme = {
    background: './background.png',
    headerBgColor: "rgb(9, 94, 84)",
  };
  */
  let [showChat, setShowChat] = useState(false);
  
  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };
  
  return (
    //<ThemeProvider theme={theme}>
    <div>
      {/*<WhatsAppWidget onClick />*/}
      {/*
      <div className = "header">
        <h2>Minha aplicação!!!</h2>
      </div>
      */}
      <div className="main">
        {/*
        <div className ="nav">
          <h3>teste</h3>
        </div>
        */}
        {/*
        <div className ="content">
          <div style = {{padding:"20px"}}>
            {/*<h1>conteudo da aplicação teste.....</h1>
            <p>teste</p>
            <p>mais conteudo</p>
          </div>
        </div>
        */}
      </div>
      {/*<div className="footer">Footer</div>*/}
      <div className="bot">
        <div style={{ display: showChat ? "" : "none" }}>
          <SimpleForm></SimpleForm>
        </div>
        {/* <div> {showChat ? <SimpleForm></SimpleForm> : null} </div> */}
        <div>
          {!showChat ? (
            <button className="btn" onClick={() => startChat()}>
              <WhatsAppWidget
                //widgetColor="#25D366" // Cor personalizada
                //headerColor="#128C7E" // Cor personalizada do cabeçalho
                //headerTitle="Chat via WhatsApp" // Título personalizado do cabeçalho
              />
            </button>
          ) : (
            <button className="btn" onClick={() => hideChat()} style={{color:'transparent'}}>
              close
              <WhatsAppWidget />
              {/*<FontAwesomeIcon icon="fa-brands fa-whatsapp" />*/}
            </button>
          )}
        </div>
      </div>
    </div>
    //</ThemeProvider>
  );
};
//export default ThemedExample;
export default App;