import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import './App.css'

const Review = (props) => {
  const [state, setState] = useState({ name: '', age: '', date: '' })
  const [dataSent, setDataSent] = useState(false) // Variável para controlar se os dados já foram enviados

  useEffect(() => {
    const { steps } = props
    const { name, age, date } = steps
    setState({ name: name.value, age: age.value, date: date.value })

    // Verificar se os dados já foram enviados
    if (!dataSent) {
      saveDataToServer({ name: name.value, age: age.value, date: date.value })
      setDataSent(true) // Marcar que os dados foram enviados
    }
  }, [props])

  const saveDataToServer = (userData) => {
    // Fazer uma requisição POST para a rota da API no servidor
    axios
      .post('http://18.230.61.232/api/save-user-data', userData)
      .then((response) => {
        console.log('Dados salvos no servidor:', response.data);
        // Aqui lógica adicional se necessário, como redirecionar o usuário para uma página de confirmação
      })
      .catch((error) => {
        console.error('Erro ao salvar dados no servidor:', error);
        // lidar com o erro, exibindo uma mensagem para o usuário ou tentando novamente, por exemplo
      });
  }

  const { name, age, date } = state

  return (
    <div style={{ width: '100%' }}>
      <h3>Resumo</h3>
      <table>
        <tbody>
          <tr>
            <td>Nome</td>
            <td>{name}</td>
          </tr>
          {/*
          <tr>
            <td>Gênero</td>
            <td>{gender}</td>
          </tr>
          */}
          <tr>
            <td>Idade</td>
            <td>{age}</td>
          </tr>
          <tr>
            <td>Data de Nascimento</td>
            <td>{date}</td>
          </tr>
          <></>
        </tbody>
      </table>
    </div>
  )
}

Review.propTypes = {
  steps: PropTypes.object,
}

Review.defaultProps = {
  steps: undefined,
}

export default Review
